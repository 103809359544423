<template>
  <div
      :class="
      'request-card '.concat(isFavorite ? 'isFavorite ' : '') + this.cssClass
    "
  >
    <div class="request-card-header">
      <div class="heading">{{ $t('packages.package_info') }}</div>
      <div class="date">{{ moment(service.created_at).format('ll') }}</div>
    </div>
    <div class="request-card-divider"></div>

    <div class="request-card-row">
      <div class="heading">{{ $t('packages.package_name') }}</div>
      <div class="value">{{ service.title }}</div>
    </div>

    <div class="request-card-row">
      <div class="heading">{{ $t('packages.journey_type') }}</div>
      <div class="value">{{ service.category }}</div>
    </div>

    <div class="request-card-row"
         v-if="service.location && service.feature_days &&  (cardType==='feature' || cardType==='both')">
      <div class="heading">{{ $t('packages.package_city2') }}</div>
      <div class="value">{{ service.location }}</div>
    </div>

    <div>

      <div class="request-card-divider"></div>
    </div>


    <div class="request-card-row">
      <div class="heading">{{ $t('requests.card.by') }}</div>
      <div class="value">{{ service.provider }}</div>
    </div>


    <div class="request-card-row" v-if="service.cancel_reason && (cardType==='cancel' || cardType==='both')">
      <div class="heading">{{ $t('requests.card.cancel_reason') }}</div>
      <div class="value">{{ service.cancel_reason }}</div>
    </div>


    <div class="request-card-row" v-if="service.feature_days && (cardType==='feature' || cardType==='both')">
      <div class="heading">{{ $t('requests.card.feature_days') }}</div>
      <div class="value">{{ service.feature_days }}</div>
    </div>


    <div v-if="showDetail" class="request-card-footer">
      <div class="btn" @click="toDetail">
        {{ $t('common.show_details') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RequestServiceCard',
  props: {
    service: {
      type: Object,
      default: () => ({
        id: 2,
        title: 'اسم الخدمة',
        excerpt: 'وصف صغير عن الخدمة',
        slug: 'اسم-الخدمة',
        city: 'صنعاء',
        country: 'اليمن',
        provider: 'الاسم العربي',
        max_people: '7',
        rate: '4.7',
        available_people: 1,
        price: 24,
        offer: {
          discount_percent: 16,
        },
        image:
            'https://images.unsplash.com/photo-1508050919630-b135583b29ab?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80',
      }),
    },
    image: String,
    cssClass: String,
    isFavorite: {
      type: Boolean,
      default: false,
    },
    showDetail: {
      type: Boolean,
      default: true,
    },
    cardType: String
  },

  methods: {
    toDetail() {
      this.$router.push({
        name: 'featureRequestServiceDetail',
        params: {
          lang: this.$i18n.locale,
          id: this.service.id,
          type: this.$route.params.type,
        },
      });
    }
  }
};
</script>
